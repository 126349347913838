<template>
    <div class="contentPageError">
        <v-container>
            <v-row justify="center">
                <v-col cols="12"  align="center">
                    <img src="~@/assets/lupaError404.png" alt="">
                </v-col>
                <v-col cols="12" align="center">
                    <h1 class="titleError">Página não </h1>
                    <h1 class="titleError">encontrada</h1>
                </v-col>
                <v-col cols="12" align="center">
                    <p class="descriptionError">
                        Ops... parece que algo deu errado e não conseguimos encontrar essa página :( 
                            <br /> Tente novamente mais tarde ou entre em contato conosco por meio do e-mail:
                    </p>
                    <p class="contactMail">faleconosco@finago.com.br</p>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn @click="$router.push({name: `customer home`})" 
                        outlined rounded class="btnBack" color="#8C8C8C">
                        Ir para Home
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            role: 'Auth/getRole',
        })
    }
}
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/commons/notFoundPage.scss";

</style>
